import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=ca39d16e&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca39d16e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Facebook: require('/vercel/path0/components/utils/Facebook.vue').default,UtilsButton: require('/vercel/path0/components/utils/UtilsButton.vue').default,LinkedIn: require('/vercel/path0/components/utils/LinkedIn.vue').default,NjSection: require('/vercel/path0/node_modules/@nujek/ui/dist/runtime/components/atoms/NjSection.vue').default})
